(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name wbOrder.controller:ItemDialogCtrl
   *
   * @description
   *
   */
  angular
    .module('wbOrder')
    .controller('ItemDialogCtrl', ItemDialogCtrl);

  function ItemDialogCtrl($mdDialog, FormControl, CardType, cardTypeMap, countries, _, $q, $rootScope, user, Cart, orderType, $scope, moment, wallets) {
    var vm = this
      , formControl = new FormControl()
      , deferred = $q.defer()
      , cart = new Cart()
      , OrderItem = cart.getOrderItem()
      , EdenredECSselectedProductType = 'EdenredEcsSelectedProductType'
      , newCardItemDialogFormTemplate = 'wb-order/views/new-card-item-dialog-form.tpl.html'
      , multiWalletCardItemDialogFormTemplate = 'wb-order/views/multi-wallet-card-item-dialog-form.tpl.html'
      , topUpItemDialogFormTemplate = 'wb-order/views/topup-item-dialog-form.tpl.html';

    cart.id = orderType;
    OrderItem = cart.getOrderItem();
    vm.itemDialogForm = {};
    vm.countries = countries;
    vm.wallets = wallets;
    vm.isNewItem = _.isEmpty(vm.item);
    vm.forceSubmittedClass = false;
    vm.selectedProductType = localStorage.getItem(EdenredECSselectedProductType);

    vm.dateOfBirth = {
      year: '',
      month: '',
      day: ''
    };

    if (vm.isNewItem && vm.selectedProductType === 'multiWallet') {
      _.forEach(wallets, function (wallet) {
        vm.item[wallet.field] = 0;
      });
    }

    vm.maxDateOfBirth = function maxDateOfBirth() {
      var today = new Date();
      var MIN_YEARS = 15;
      return today.getFullYear() - MIN_YEARS;
    };

    CardType.query().$promise
      .then(function (data) {
        vm.cardTypes = _.filter(data, {'category': cardTypeMap[vm.selectedProductType]});
      });

    vm.isNewCustomer = function isNewCustomer() {
      return _.isEmpty(user);
    };

    vm.isOrderNewCardType = function isOrderNewCardType() {
      return vm.orderType === '1';
    };

    if (vm.isOrderNewCardType() && _.isUndefined(vm.item.dateOfBirth)) {
      vm.item.dateOfBirth = null;
      formControl.setSuccessText('order.unique.toast.save');
    } else {
      formControl.setSuccessText('order.unique.toast.edit');
    }

    if (!_.isUndefined(vm.item.dateOfBirth) || !_.isEmpty(vm.item.dateOfBirth)) {
      var dateOfBirthString = moment(vm.item.dateOfBirth).format('YYYY-MM-DD').split('-');
      vm.dateOfBirth.year = parseInt(dateOfBirthString[0], 10);
      vm.dateOfBirth.month = parseInt(dateOfBirthString[1], 10);
      vm.dateOfBirth.day = parseInt(dateOfBirthString[2], 10);
    }

    $scope.$watchCollection(function () {
      return vm.dateOfBirth;
    }, buildDateOfBirth);

    function buildDateOfBirth(date) {
      vm.item.dateOfBirth = moment([date.year, date.month, date.day].join('-')).toDate();
    }

    vm.itemForm = (vm.isOrderNewCardType()) ? newCardItemDialogFormTemplate :
    vm.selectedProductType === 'multiWallet' ? multiWalletCardItemDialogFormTemplate : topUpItemDialogFormTemplate;

    vm.closeDialog = function closeDialog() {
      $mdDialog.hide();
    };

    vm.isProcessing = function isProcessing() {
      return formControl.isProcessing();
    };

    vm.getServerValidation = function getServerValidation() {
      return formControl.getServerValidation();
    };

    vm.submit = function submit() {
      deferred = $q.defer();

      formControl
        .process(deferred.promise)
        .then(function () {
          $rootScope.$broadcast('itemList.refresh');
          vm.closeDialog();
        });

      if (vm.isNewItem) {
        vm.item.$save(deferred.resolve, deferred.reject);
      } else {
        vm.item.$update(deferred.resolve, deferred.reject);
      }
    };

    vm.submitAndCopy = function submitAndCopy() {
      if (!vm.itemDialogForm.$valid) {
        vm.forceSubmittedClass = true;
        return;
      }

      deferred = $q.defer();

      formControl
        .process(deferred.promise)
        .finally(function () {
          clearCardInfo();
          $rootScope.$broadcast('itemList.refresh');
        });

      vm.item.$save(deferred.resolve, deferred.reject);

      function clearCardInfo() {
        vm.dateOfBirth = {
          year: '',
          month: '',
          day: ''
        };
        vm.item = angular.copy({dateOfBirth: null}, new OrderItem());
        vm.itemDialogForm.$setPristine();
        vm.itemDialogForm.$setUntouched();
      }
    };

    vm.openComparisonDialog = function openComparisonDialog() {
      $mdDialog.show({
        parent: angular.element(document.body),
        templateUrl: 'wb-order/views/card-comparison-dialog.tpl.html',
        clickOutsideToClose: true,
        focusOnOpen: false,
        locals: {
          item: vm.item
        },
        controllerAs: 'comparisonDialog',
        controller: function comparisonDialog($mdDialog, item, _) {
          var vm = this;

          vm.closeDialog = function closeDialog() {
            if (_.isNull(item.dateOfBirth)) {
              delete item.dateOfBirth;
            }

            $mdDialog.hide()
              .then(function () {
                $rootScope.$broadcast('itemList.openItemDialog', item);
              });
          };
        }
      });
    };
  }
}());
